import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import loadable from "@loadable/component"

import { ProductSection } from "../product/productSection.component"
import { useViewLPSPageTracking, useProductDetailTracking } from "../thirdParties/gtm/tagManager"
import { isWysiwygEmpty } from "../../utils/wysiwyg"
import CampaignBanner from "./campaignBanner.component"
import { BANNER_TEMPLATES } from "../landing/banner.utils"
import CampaignAnchor from "./campaignAnchor.component"
import { useBreakpoint } from "../structure/theme"
import { useProductStickyButton } from "../product/useProductStickyButton"
import { useOverlayAddBottomComponent } from "../structure/overlay.provider"
import { useTranslation } from "../translation/translate.component"
import { useUserContext } from "../navigation/user.provider"
import useNavigation from "../navigation/useNavigation"
import DynamicBlock from "../../components/block/dynamicBlock.component"

const ProductPageSticky = loadable(() => import(`../product/productPageSticky.component`), { ssr: false })

export default function SimpleCampaignPage ({
  data: {
    campaign: {
      bannerImg,
      bannerMobileImg,
      pictureCover,
      pictureCoverPreview,
      campaignDescription,
      name,
      campaignHook,
      landingPageProducts,
      coveritsVariant,
      bannerTemplate,
      bannerBgColor,
      bannerBgGradientColor,
      bannerButtonBgColor,
      campaignContext,
      upsellLinkHook,
      upsellCampaign,
      upsellHook,
    },
    allFaq,
  },
}) {
  const { t } = useTranslation()
  const { resolveRoute } = useNavigation()

  const lpsProduct = landingPageProducts[0]
  const ANCHORS = [
    {
      name: t(`common:campaign.banner_product_anchor_three_steps`),
      link: `urgences3Steps`,
    },
    {
      name: t(`common:campaign.banner_product_anchor_product_description`),
      link: `productDescription`,
    },
    {
      name: t(`common:campaign.banner_product_anchor_product_cover`),
      link: `schemaCover`,
    },
    {
      name: t(`common:campaign.banner_product_anchor_product_faq`),
      link: `faq`,
    },
  ]

  const product = useMemo(() => {
    return {
      ...lpsProduct,
      impactProduct: {
        ...lpsProduct.impactProduct,
        variants: coveritsVariant.slice(0, 1),
      },
    }
  }, [lpsProduct, coveritsVariant])

  const [upsellProduct, upsellLink] = useMemo(() => {
    if (!upsellCampaign) {
      return [null, ``]
    }

    const _upsellProduct = {
      ...upsellCampaign.landingPageProducts[0],
      impactProduct: {
        ...upsellCampaign.landingPageProducts[0].impactProduct,
        variants: upsellCampaign.coveritsVariant.slice(0, 1),
      },
    }

    let _upsellLink

    if (upsellCampaign.coBrand) {
      _upsellLink = resolveRoute(`coBrandLps`, {
        ...upsellCampaign,
        lang: upsellCampaign.coBrand.lang,
      })
    } else {
      _upsellLink = resolveRoute(`campaign`, upsellCampaign)
    }

    return [_upsellProduct, _upsellLink]
  }, [upsellCampaign, resolveRoute])

  const windowIsSmall = useBreakpoint() === `small`
  const { usePhoneNumber } = useUserContext()
  const phoneNumber = usePhoneNumber()

  useViewLPSPageTracking(name)
  useProductDetailTracking([product])

  const [ref, sticky] = useProductStickyButton()

  useOverlayAddBottomComponent(
    () => ({
      Component: ProductPageSticky,
      props: {
        product,
        phoneNumber,
        bannerButtonBgColor,
      },
      isVisible: sticky,
      bottomSpace: 90,
    }),
    [product, sticky],
  )

  return (
    <>
      {(bannerImg || !isWysiwygEmpty(campaignHook)) && (
        <CampaignBanner
          template={bannerTemplate}
          bannerImg={bannerImg}
          bannerMobileImg={bannerMobileImg}
          descriptionProduct={campaignDescription}
          description={campaignHook}
          bannerBgColor={bannerBgColor}
          withCta={false}
          bannerBgGradientColor={bannerBgGradientColor}
          bannerButtonBgColor={bannerButtonBgColor}
          campaignContext={campaignContext}
          refPaymentButton={ref}
          phoneNumber={phoneNumber}
          product={product}
          upsellProduct={upsellProduct}
          upsellLink={upsellLink}
          upsellLinkHook={upsellLinkHook}
          upsellHook={upsellHook}
        />
      )}
      {bannerTemplate !== BANNER_TEMPLATES.PRODUCT && (
        <ProductSection
          product={product}
          upsellProduct={upsellProduct}
          upsellLink={upsellLink}
          upsellLinkHook={upsellLinkHook}
          upsellHook={upsellHook}
          refPaymentButton={ref}
          pictureCover={pictureCover}
          pictureCoverPreview={pictureCoverPreview}
          description={campaignDescription}
        />
      )}
      {bannerTemplate === BANNER_TEMPLATES.PRODUCT && !windowIsSmall && <CampaignAnchor ancres={ANCHORS} />}

      {bannerTemplate === BANNER_TEMPLATES.PRODUCT && (
        <DynamicBlock
          __component="Warranties"
          data-testid="warranties_block"
          blockTitle={t(`common:campaign.section_warranties_title`)}
        />
      )}

      <DynamicBlock
        __component="ThreeSteps"
        data-testid="three_steps"
        blockTitle={t(`common:information.section_three_steps_title`)}
      />
      <DynamicBlock
        __component="ProductDescription"
        blockTitle={t(`common:product.section_description`)}
        blockAnchor="productDescription"
        product={product}
      />
      <DynamicBlock
        __component="SchemaCover"
        data-testid="schema_block"
        blockTitle={
          isWysiwygEmpty(product.coverTitle) ? t(`common:product.section_schema_image_alt`) : product.coverTitle
        }
        blockAnchor="schemaCover"
        product={product}
      />
      <DynamicBlock
        __component="Faq"
        data-testid="faq_block"
        blockTitle={t(`common:campaign.section_faq_title`)}
        questions={allFaq.nodes}
        double
      />
      {bannerTemplate !== BANNER_TEMPLATES.PRODUCT && (
        <DynamicBlock
          __component="Warranties"
          data-testid="warranties_block"
          blockTitle={t(`common:campaign.section_warranties_title`)}
        />
      )}
    </>
  )
}

SimpleCampaignPage.propTypes = {
  data: PropTypes.shape({
    campaign: PropTypes.shape({
      bannerImg: PropTypes.object,
      bannerMobileImg: PropTypes.object,
      pictureCover: PropTypes.object,
      pictureCoverPreview: PropTypes.object,
      name: PropTypes.string.isRequired,
      campaignHook: PropTypes.string,
      landingPageProducts: PropTypes.arrayOf(
        PropTypes.shape({
          originalId: PropTypes.number.isRequired,
          impactProduct: PropTypes.object.isRequired,
        }).isRequired,
      ).isRequired,
      coveritsVariant: PropTypes.array.isRequired,
      campaignDescription: PropTypes.string,
      bannerTemplate: PropTypes.string,
      bannerBgColor: PropTypes.string,
      bannerBgGradientColor: PropTypes.string,
      bannerButtonBgColor: PropTypes.string,
      campaignContext: PropTypes.string,
      upsellLink: PropTypes.string,
      upsellLinkHook: PropTypes.string,
      upsellCampaign: PropTypes.shape({
        coBrand: PropTypes.shape({
          slug: PropTypes.string.isRequired,
          lang: PropTypes.string.isRequired,
        }),
        coveritsVariant: PropTypes.array.isRequired,
        landingPageProducts: PropTypes.arrayOf(
          PropTypes.shape({
            impactProduct: PropTypes.object.isRequired,
          }).isRequired,
        ).isRequired,
      }),
      upsellHook: PropTypes.string,
    }).isRequired,
    allFaq: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
}

export const pageQuery = graphql`
  query SimpleCampaignById($id: String!, $tagRegex: String!) {
    campaign(id: { eq: $id }) {
      name
      bannerTemplate
      bannerBgColor
      bannerBgGradientColor
      bannerButtonBgColor
      bannerImg {
        ...responsiveRemoteImage
      }
      bannerMobileImg {
        file {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        alt
        title
      }
      pictureCover {
        file {
          childImageSharp {
            fluid(maxWidth: 1024, srcSetBreakpoints: [600, 1024]) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        alt
        title
      }
      pictureCoverPreview: pictureCover {
        ...previewRemoteImage
      }
      campaignDescription
      campaignHook
      campaignContext
      landingPageProducts {
        ...productPageAndSimpleCampaign
      }
      coveritsVariant {
        id
        offerCode
        areaCode
        standardPrice
        offerPrice
        campaignEndDate
      }
      upsellLinkHook
      upsellHook
      upsellCampaign {
        coBrand {
          slug
          brandLocale
        }
        slug
        coveritsVariant {
          id
          offerCode
          areaCode
          standardPrice
          offerPrice
          campaignEndDate
        }
        landingPageProducts {
          ...upsellProduct
        }
      }
    }
    allFaq(
      filter: { inlineTags: { regex: $tagRegex }, channels: { elemMatch: { name: { eq: "Site core - Produit" } } } }
    ) {
      nodes {
        id
        question
        answer
        position
      }
    }
  }
`
