import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import SmoothAnchor from "../navigation/smoothAnchor.component"
import { colors } from "../structure/theme"

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 62px;
  border-top: 3px solid black;
  border-bottom: 3px solid black;
  margin-top: 1px;
`

const AnchorContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 1000px;
`

const Anchor = styled.a`
  font-weight: 500;
  color: ${colors(`grey.g700`)};
`

export default function CampaignAnchors ({ ancres }) {
  return (
    <Container data-testid="ancres">
      <AnchorContainer>
        {ancres.map(({ name, link }) => (
          <SmoothAnchor key={name} href={`#${link}`} Component={Anchor}>
            {name}
          </SmoothAnchor>
        ))}
      </AnchorContainer>
    </Container>
  )
}

CampaignAnchors.propTypes = {
  ancres: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
}
